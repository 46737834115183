import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faHotel, faBus,  faArrowRight, faHeart
} from '@fortawesome/free-solid-svg-icons';
import { Star } from 'react-bootstrap-icons';

const PackageCard = (props) => {
  const { price, duration, people, image, location, title, reviews } = props;

  return (
    <div className="tour-package-card">
      <div className="tour-package-image">
        <img src={image} alt={title} className="image-placeholder" />
        <div className="tour-price"><strong>₹{price}</strong> <span>/ per person</span></div>
      </div>
      <div className="tour-info">
          <span>{duration}</span> | <span>People: {people}</span> | <span>{location}</span>
        </div>
      <div className="tour-package-details">
      
        <h3 className="tour-title">{title}</h3>
        <div className="tour-reviews">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg> <Star/> ({reviews} reviews)
        </div>
        <div className="tour-fact">
          <span>{duration}</span> | <span>People: {people}</span> | <span>{location}</span><hr />
        </div>
        <div className="tour-duration">
          <span></span>
        </div>
        <div className="icons-container">
          <FontAwesomeIcon icon={faPlane} /><span> Flight | </span>
          <FontAwesomeIcon icon={faHotel} /><span> Hotels | </span>
          <FontAwesomeIcon icon={faBus} /><span> Transport </span>

        </div>   <hr />  
           <div className="tour-actions">
          <button className="book-now">Book Now <FontAwesomeIcon icon={faArrowRight}/></button>
          <button className="wish-list">Wish List <FontAwesomeIcon icon={faHeart}/></button>
        </div>
      </div>
    </div>
  );
};
export default PackageCard;
