import React from 'react';
import Icon6 from '../../assets/images/icon6.png';
import Icon7 from '../../assets/images/icon7.png';
import Icon8 from '../../assets/images/icon8.png';
import Icon9 from '../../assets/images/icon9.png';
import { faPlane, faHotel, faBus,  faArrowRight, faHeart
} from '@fortawesome/free-solid-svg-icons';
import './TourPackages.css';

function TourPackages() {
  const activityData = [
    {
      icon: Icon6,
      title: "Save Time",
      destinations:"No need to surf Multiple Sites for packages, quotes, travel plans"
    },
    {
      icon: Icon7,
      title: "Multiple Options",
      destinations: "Get Multiple Itineraries & Personalised Suggestions from our Travel agents"
    },
    {
      icon: Icon8,
      title: "SAVE MONEY",
      destinations: "Compare, Negotiate & Choose the best from multiple options"
    },
    {
      icon: Icon9,
      title: "TRUSTED NETWORK",
      destinations: "Of 500+ Hotels Reliable & Authentic Travel Guides in Goa"
    },
  ];

  return (
    <>
  <section className="activity-section">
              <div className="container">
                  <div className="section-heading text-center">
                      <div className="row">
                          <div className="col-lg-8 offset-lg-2">
                              <h3 className="dash-style">Customize & Book Festive</h3>
                              <h2>Goa Tour Packages</h2>
                              <p>Experience the best in travel with our Best Holiday Packages to Top Notch Destinations. Don't miss out on these exclusive deals!.</p>
                          </div>
                      </div>
                  </div>
                  <div className="activity-inner row">
                      {activityData.map((activity, index) => (
                          <div key={index} className="col-lg-2 col-md-4 col-sm-6">
                              <div className="activity-item">
                                  <div className="activity-icon">
                                      <a href="#">
                                          <img src={activity.icon} alt={activity.title} />
                                      </a>
                                  </div>
                                  <div className="activity-content">
                                      <h5>
                                          <a className='activity-title' href="#">{activity.title}</a>
                                      </h5>
                                      <p>{activity.destinations} Destination{activity.destinations > 1 ? 's' : ''}</p>
                                  </div>
                              </div>
                          </div>
                      ))}
                  </div>
              </div>
          </section></>
  );
}

export default TourPackages;
