import React from 'react'
import "../styles/Home.css"
import {Container,Row, Col} from 'reactstrap'
import heroImg from "../assets/images/hero-img01.jpg"
import heroImg2 from "../assets/images/hero-img02.jpg"
import heroVideo from "../assets/images/hero-video.mp4"
import Subtitle from '../Shared/Subtitle'
import worldImg from "../assets/images/world.png"
import experienceImage from "../assets/images/experience.png"
import SearchBar from '../Shared/SearchBar'
import ServiceList from '../Services/ServiceList'
import FeaturedToursList from '../Components/FeaturedTours/FeaturedToursList'
import MasonryImagesGallery from '../Components/Image-gallery/MasonryImagesGallery'
import Testimonials from '../Components/Testimonials/Testimonials'
import Newsletter from '../Shared/Newsletter'
import Contact from './Contact'
import FeaturedBlogsList from '../Components/FeaturedBlogs.jsx/FeaturedBlogsList'
import Packages from '../Components/Packages'

const Home = () => {
  return (
    <>
    <section>
      <Container>
        <Row>
          <Col lg="6">
            <div className="hero__content">
              <div className="hero__subtitle d-flex align-items-center">
                <Subtitle subtitle={"Welcome to Goa Explorer "}/>
                <img src={worldImg} alt="" />
              </div>
              <h1>
              Discover Goa: Your Ultimate {" "}
                <span className="highlight">Travel Guide</span>
              </h1>
              <p>
              🎉 Welcome to our vibrant corner of the internet, where the adventure begins! Dive into the heart of Goa's beauty and excitement with our interactive travel guide. 🗺️ From pristine beaches to bustling markets, ancient forts to lively nightlife, we've curated the ultimate experience for every traveler. 🏖️ Choose your path, explore hidden gems, and create memories that will last a lifetime. Ready to embark on an unforgettable journey? Let's start exploring together! 🚀 
              </p>
            </div>
          </Col>
          <Col lg="2">
            <div className="hero__img-box">
              <img src={heroImg} alt="" />
            </div>
          </Col>
          <Col lg="2">
            <div className="hero__img-box video-box mt-4">
              <video src={heroVideo} alt="" autoPlay loop muted />
            </div>
          </Col>
          <Col lg="2">
            <div className="hero__img-box mt-5">
              <img src={heroImg2} alt="" />
            </div>
          </Col>
          {/* <SearchBar/> */}
        </Row>
      </Container>
    </section>
    <section>
      <Container>
        <Row>
          <Col lg="12">
            <h5 className="services__subtitle">Our Best of Tour Packages</h5>
            <h2 className="services__title">We Offer Our Best Packages</h2>
          </Col>
          </Row>
          <Packages/>
      </Container>
    </section>

    <section>
      <Container>
        <Row>
          <Col lg="12">
            <h5 className="services__subtitle">What We Serve</h5>
            <h2 className="services__title">We Offer Our Best Services</h2>
          </Col>
          </Row>
          <ServiceList/>
      </Container>
    </section>

    <section>
      <Container>
        <Row>
          <Col lg="12" className='mb-5'>
            <Subtitle subtitle={"Explore Packages"}/>
            <h2 className="featured__tour-title">Our Featured Tours</h2>
          </Col>
          <FeaturedToursList/>
        </Row>
      </Container>
    </section>

    <section>
      <Container>
        <Row>
          <Col lg="6">
            <div className="experience__content">
              <Subtitle subtitle={"Embrace Goa: Our Presence in Paradise"}/>
              <h2>Connecting You to Goa's Essence,  <br /> One Experience at a Time</h2>
              <p>our vibrant presence in the heart of Goa! At Goa Explorer, we're more than just a travel company. 
                
                we're your gateway to experiencing the soul of this beautiful destination. Through our carefully curated experiences, personalized services, and passionate team, we strive to connect you with the essence of Goa. 
              </p>
            </div>

            <div className="couter__wrapper d-flex align-items-center gap-5">
              <div className="counter__box">
                <span>12k+</span>
                <h6>Successful Trips</h6>
              </div>
              <div className="counter__box">
                <span>2k+</span>
                <h6>Regular Clients</h6>
              </div>
              <div className="counter__box">
                <span>15+</span>
                <h6>Years Experience</h6>
              </div>
            </div>

          </Col>
          <Col lg="6">
            <div className="experience__img">
              <img src={experienceImage} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section>
      <Container>
        <Row>
          <Col lg="12">
            <Subtitle subtitle={"Gallery"}/>
            <h2 className="gallery__title">
              Visit Our Customers Tour Gallery
            </h2>
          </Col>
          <Col lg="12">
            <MasonryImagesGallery/>
          </Col>
        </Row>
      </Container>
    </section>
    <section>
      <Container>
        <div className="title">
          <Subtitle subtitle={"Featured Blogs"} />
        </div>
        <Row>
      <FeaturedBlogsList lg={4} md={6} sm={6}/>
        </Row>
      </Container>
    </section>
    <section>
      <Container>
        <Row>
          <Col lg="12">
            <Subtitle subtitle={"Testimonial"}/>
            <h2 className="testmonials__title">What our Customers Say about us</h2>
          </Col>
          <Testimonials/>
        </Row>
      </Container>
    </section>
    <Contact/>
    <Newsletter/>
    </>
  )
}

export default Home