import React from 'react'
import ServiceCard from './ServiceCard'
import { Col,Row } from 'reactstrap'
import weatherImg from "../assets/images/weather.png"
import guideImg from "../assets/images/guide.png"
import customizationImg from "../assets/images/customization.png"
import toursImg from "../assets/images/tours.png"

const serviceData =[
    {
        imgUrl: weatherImg,
        title: "Transportion Services",
        desc: "Discover the ease of travel with our all-in-one transportation hub. Whether it's a bike for coastal adventures, a taxi for city hops, or a car for scenic drives, find your perfect ride."
    },
    {
        imgUrl: guideImg,
        title: "Adventure Sport",
        desc: "Fuel your thrill-seeking spirit with our seasoned guides. Whether you're into surfing, hiking, or diving, our experts ensure an exhilarating and safe journey into the heart of adventure."
    },
    {
        imgUrl: customizationImg,
        title: "Custimized Packages",
        desc: "Craft your ideal getaway with our tailored packages. From romantic retreats to action-packed excursions, we specialize in curating experiences that match your unique travel preferences."
    },
    {
        imgUrl: toursImg,
        title: "Hidden Beauty of Goa",
        desc: "Unveil Goa's secrets with our curated tours. From secluded beaches to ancient landmarks, embark on a journey of discovery and unlock the hidden gems of this enchanting destination."
      }
]

const ServiceList = () => {
  return (
    <div>
        {
              <Row>
              {serviceData.map((item, index) => (
                <Col lg="3" md="6" sm="12" className='mb-4' key={index}>
                  <ServiceCard item={item} />
                </Col>
              ))}
            </Row>
        }
    </div>
  )
}

export default ServiceList