import React from 'react';
import PackageCard from './PackageCard';
import TourPackages from './TourPackages';
import { Col, Row } from 'reactstrap';
import Img01 from '../../assets/images/tour-img01.jpg';
import Img02 from '../../assets/images/tour-img02.jpg';
import Img03 from '../../assets/images/tour-img03.jpg';
import Img04 from '../../assets/images/tour-img04.jpg';
import Img05 from '../../assets/images/tour-img05.jpg';

function Packages() {
  const packagesData = [
    {
      price: "1,900",
      duration: "7D/6N",
      people: "5",
      image: Img01,
      location: "North Goa",
      title: "Sunset view of beautiful lakeside resident",
      reviews: "25",
      rating: "3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit luctus nec ullam. Ut elit tellus, luctus nec ullam elit tellus."
    },
    {
      price: "1,900",
      duration: "7D/6N",
      people: "5",
      image: Img02,
      location: "Malaysia",
      title: "Sunset view of beautiful lakeside resident",
      reviews: "25",
      rating: "3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit luctus nec ullam. Ut elit tellus, luctus nec ullam elit tellus."
    },
    {
      price: "1,900",
      duration: "7D/6N",
      people: "5",
      image: Img03,
      location: "Malaysia",
      title: "Sunset view of beautiful lakeside resident",
      reviews: "25",
      rating: "3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit luctus nec ullam. Ut elit tellus, luctus nec ullam elit tellus."
    },

    {
      price: "1,900",
      duration: "7D/6N",
      people: "5",
      image: Img04,
      location: "Malaysia",
      title: "Sunset view of beautiful lakeside resident",
      reviews: "25",
      rating: "3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit luctus nec ullam. Ut elit tellus, luctus nec ullam elit tellus."
    },
    {
      price: "1,900",
      duration: "7D/6N",
      people: "5",
      image: Img05,
      location: "Malaysia",
      title: "Sunset view of beautiful lakeside resident",
      reviews: "25",
      rating: "3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit luctus nec ullam. Ut elit tellus, luctus nec ullam elit tellus."
    },
    {
      price: "1,900",
      duration: "7D/6N",
      people: "5",
      image: Img01,
      location: "Malaysia",
      title: "Sunset view of beautiful lakeside resident",
      reviews: "25",
      rating: "3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit luctus nec ullam. Ut elit tellus, luctus nec ullam elit tellus."
    },
    {
      price: "1,900",
      duration: "7D/6N",
      people: "5",
      image: Img01,
      location: "Malaysia",
      title: "Sunset view of beautiful lakeside resident",
      reviews: "25",
      rating: "3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit luctus nec ullam. Ut elit tellus, luctus nec ullam elit tellus."
    },
    {
      price: "1,900",
      duration: "7D/6N",
      people: "5",
      image: Img01,
      location: "Malaysia",
      title: "Sunset view of beautiful lakeside resident",
      reviews: "25",
      rating: "3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit luctus nec ullam. Ut elit tellus, luctus nec ullam elit tellus."
    },

    // Add more package data here if needed
  ];

  return (
    <><div className="container">
      <Row>
        {packagesData.map((pkg, index) => (
          <Col lg="3" md="6" sm="12" className='mb-4' key={index}>
            <PackageCard
              price={pkg.price}
              duration={pkg.duration}
              people={pkg.people}
              location={pkg.location}
              title={pkg.title}
              reviews={pkg.reviews}
              rating={pkg.rating}
              description={pkg.description}
              image={pkg.image} />
          </Col>
        ))}
      </Row>
    </div><TourPackages /></>
  );
}

export default Packages;
